import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import Container from '../common/Container';
import LandingParagraph from './common/LandingParagraph';
import LandingSection from './common/LandingSection';
import StoresButtonGroup from './common/StoresButtonGroup';
import ShareSection1It from '../../../assets/images/it/ShareSection1_it.png';
import ShareSection2It from '../../../assets/images/it/ShareSection2_it.png';
import ShareSection3It from '../../../assets/images/it/ShareSection3_it.png';
import ShareSection1Es from '../../../assets/images/es/ShareSection1_es.png';
import ShareSection2Es from '../../../assets/images/es/ShareSection2_es.png';
import ShareSection3Es from '../../../assets/images/es/ShareSection3_es.png';
import ShareSection1Uk from '../../../assets/images/uk/ShareSection1_uk.png';
import ShareSection2Uk from '../../../assets/images/uk/ShareSection2_uk.png';
import ShareSection3Uk from '../../../assets/images/uk/ShareSection3_uk.png';
import ShareSection1Us from '../../../assets/images/us/ShareSection1_us.png';
import ShareSection2Us from '../../../assets/images/us/ShareSection2_us.png';
import ShareSection3Us from '../../../assets/images/us/ShareSection3_us.png';
import LocalizedImage from '../common/LocalizedImage';
const subsections = [
    {
        images: [
            { lang: 'it', src: ShareSection1It },
            { lang: 'es', src: ShareSection1Es },
            { lang: 'uk', src: ShareSection1Uk },
            { lang: 'us', src: ShareSection1Us },
        ],
    },
    {
        images: [
            { lang: 'it', src: ShareSection2It },
            { lang: 'es', src: ShareSection2Es },
            { lang: 'uk', src: ShareSection2Uk },
            { lang: 'us', src: ShareSection2Us },
        ],
    },
    {
        images: [
            { lang: 'it', src: ShareSection3It },
            { lang: 'es', src: ShareSection3Es },
            { lang: 'uk', src: ShareSection3Uk },
            { lang: 'us', src: ShareSection3Us },
        ],
    },
];
export default function ShareSection() {
    const { t } = useTranslation();
    return (_jsx(LandingSection, { children: _jsxs(Container, { children: [_jsx("div", { className: "mx-auto max-w-6xl space-y-2 pb-10 lg:pb-16", children: subsections.map(({ images }, index) => (_jsx(Subsection, { images: images, title: t(`landing.share-section.t${index + 1}`), paragraph: `landing.share-section.p${index + 1}`, inverted: index == 1 }))) }), _jsx(StoresButtonGroup, { className: "mb-14 flex w-full justify-center space-x-4 lg:mb-20" })] }) }));
}
function Subsection({ images, title, paragraph, inverted, }) {
    return (_jsxs("div", { className: `block items-center justify-between gap-8 lg:flex ${inverted ? 'flex-row-reverse' : ''}`, children: [_jsx(LocalizedImage, { sources: images, alt: "App screen", className: "w-full lg:max-w-[450px]" }), _jsxs("div", { className: "mx-auto max-w-[500px] pb-8 pt-4 lg:mx-0", children: [_jsx("h2", { className: "pb-2 text-center text-3xl lg:text-left", children: title }), _jsx(LandingParagraph, { className: "text-center text-2xl lg:text-left", children: _jsx(Trans, { i18nKey: paragraph }) })] })] }));
}
