import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import uk from './locales/uk';
import us from './locales/us';
import es from './locales/es';
import it from './locales/it';
// import pt from './locales/pt';
import customPathLanguageDetector from './utils/customPathLanguageDetector';
const languageDetector = new LanguageDetector();
languageDetector.addDetector(customPathLanguageDetector);
i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
    fallbackLng: 'uk',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
        order: ['customPath'],
        caches: ['localStorage'],
    },
    resources: {
        uk: {
            translation: uk,
        },
        us: {
            translation: us,
        },
        it: {
            translation: it,
        },
        es: {
            translation: es,
        },
        /* pt: {
          translation: pt,
        }, */
    },
    react: {
        transKeepBasicHtmlNodesFor: ['strong'],
        transSupportBasicHtmlNodes: true,
    },
});
export default i18n;
