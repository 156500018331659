import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import PaymentSectionIt from '../../../assets/images/it/PaymentSection_it.png';
import PaymentSectionEs from '../../../assets/images/es/PaymentSection_es.png';
import PaymentSectionUk from '../../../assets/images/uk/PaymentSection_uk.png';
import PaymentSectionUs from '../../../assets/images/us/PaymentSection_us.png';
import Container from '../common/Container';
import LandingSection from './common/LandingSection';
import LandingParagraph from './common/LandingParagraph';
import LocalizedImage from '../common/LocalizedImage';
export default function PaymentSection() {
    const { t } = useTranslation();
    return (_jsx(LandingSection, { children: _jsx(Container, { children: _jsx("div", { className: "md:flex md:min-h-[1080px] md:justify-center", children: _jsxs("div", { className: "space-y-8 py-20 text-day-mode-text-primary lg:grid lg:grid-cols-2  lg:items-center lg:gap-32 lg:space-y-0", children: [_jsxs("div", { className: "order-2 mx-auto max-w-xl space-y-6", children: [_jsx("h2", { className: "text-center text-4xl font-medium lg:text-left lg:text-6xl", children: t('landing.payment-section.title') }), _jsx(LandingParagraph, { className: "text-center lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.payment-section.p1' }) }), _jsx(LandingParagraph, { className: "text-center lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.payment-section.p2' }) }), _jsx(LandingParagraph, { className: "text-center lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.payment-section.p3' }) }), _jsx(LandingParagraph, { className: "text-center lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.payment-section.p4' }) }), _jsx(LandingParagraph, { className: "text-center lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.payment-section.p5' }) })] }), _jsx("div", { className: "flex justify-center", children: _jsx(LocalizedImage, { sources: [
                                    { lang: 'it', src: PaymentSectionIt },
                                    { lang: 'es', src: PaymentSectionEs },
                                    { lang: 'uk', src: PaymentSectionUk },
                                    { lang: 'us', src: PaymentSectionUs },
                                ], alt: "App screen", className: "w-full max-w-[250px] md:max-w-[295px]" }) })] }) }) }) }));
}
