import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import NeedsSectionIt from '../../../assets/images/it/NeedsSection_it.png';
import NeedsSectionEs from '../../../assets/images/es/NeedsSection_es.png';
import NeedsSectionUk from '../../../assets/images/uk/NeedsSection_uk.png';
import NeedsSectionUs from '../../../assets/images/us/NeedsSection_us.png';
import Container from '../common/Container';
import LandingSection from './common/LandingSection';
import LandingParagraph from './common/LandingParagraph';
import LocalizedImage from '../common/LocalizedImage';
export default function GroupSection() {
    const { t } = useTranslation();
    return (_jsx(LandingSection, { children: _jsx(Container, { children: _jsx("div", { className: "flex justify-center", children: _jsxs("div", { className: "space-y-8 py-20 text-day-mode-text-primary", children: [_jsx("div", { className: "space-y-8 sm:mx-auto", children: _jsxs("h2", { className: "text-center text-4xl font-medium lg:text-6xl", children: [t('landing.group-section.title1'), _jsx("br", {}), t('landing.group-section.title2')] }) }), _jsxs("div", { className: "flex flex-col items-center justify-center lg:flex-row lg:items-start", children: [_jsx(LandingParagraph, { className: "order-3 shrink-0 grow-0 text-center lg:order-none  lg:mt-64 lg:w-[250px]", children: _jsx(Trans, { i18nKey: 'landing.group-section.p1' }) }), _jsx(LocalizedImage, { sources: [
                                        { lang: 'it', src: NeedsSectionIt },
                                        { lang: 'es', src: NeedsSectionEs },
                                        { lang: 'uk', src: NeedsSectionUk },
                                        { lang: 'us', src: NeedsSectionUs },
                                    ], alt: "App screen", className: "mb-8 w-full max-w-[400px]" }), _jsx(LandingParagraph, { className: "shrink-0 grow-0 text-center lg:mt-64 lg:w-[250px]", children: _jsx(Trans, { i18nKey: 'landing.group-section.p2' }) })] })] }) }) }) }));
}
