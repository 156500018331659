import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import ForgetSectionLeftIt from '../../../assets/images/it/ForgetSectionLeft_it.png';
import ForgetSectionRightIt from '../../../assets/images/it/ForgetSectionRight_it.png';
import ForgetSectionLeftEs from '../../../assets/images/es/ForgetSectionLeft_es.png';
import ForgetSectionRightEs from '../../../assets/images/es/ForgetSectionRight_es.png';
import ForgetSectionLeftUk from '../../../assets/images/uk/ForgetSectionLeft_uk.png';
import ForgetSectionRightUk from '../../../assets/images/uk/ForgetSectionRight_uk.png';
import ForgetSectionLeftUs from '../../../assets/images/us/ForgetSectionLeft_us.png';
import ForgetSectionRightUs from '../../../assets/images/us/ForgetSectionRight_us.png';
import Container from '../common/Container';
import LandingSection from './common/LandingSection';
import LandingParagraph from './common/LandingParagraph';
import LocalizedImage from '../common/LocalizedImage';
export default function ForgetSection() {
    const { t } = useTranslation();
    return (_jsx(LandingSection, { bg: "light-blue", children: _jsx(Container, { children: _jsx("div", { className: "lg:flex lg:min-h-[1080px] lg:justify-center", children: _jsxs("div", { className: "w-full space-y-8 py-20 text-day-mode-text-primary lg:max-h-[720px]", children: [_jsxs("div", { className: "order-2 mx-auto space-y-6 md:max-w-5xl", children: [_jsx("h2", { className: "text-center text-4xl font-medium lg:text-6xl", children: t('landing.forget-section.title') }), _jsx(LandingParagraph, { className: "text-center", children: _jsx(Trans, { i18nKey: 'landing.forget-section.p1' }) }), _jsx(LandingParagraph, { className: "text-center", children: _jsx(Trans, { i18nKey: 'landing.forget-section.p2' }) }), _jsx(LandingParagraph, { className: "text-center", children: _jsx(Trans, { i18nKey: 'landing.forget-section.p3' }) }), _jsx(LandingParagraph, { className: "hidden text-center lg:block", children: _jsx(Trans, { i18nKey: 'landing.forget-section.p4' }) })] }), _jsxs("div", { className: "relative mx-auto flex w-full max-w-[385px] md:max-w-[750px] lg:max-w-[950px]", children: [_jsx(LocalizedImage, { sources: [
                                        { lang: 'it', src: ForgetSectionRightIt },
                                        { lang: 'es', src: ForgetSectionRightEs },
                                        { lang: 'uk', src: ForgetSectionRightUk },
                                        { lang: 'us', src: ForgetSectionRightUs },
                                    ], alt: "App screen", className: "absolute right-0 top-4 z-10 w-full max-w-[250px] sm:top-12 md:max-w-[450px] lg:right-20" }), _jsx(LocalizedImage, { sources: [
                                        { lang: 'it', src: ForgetSectionLeftIt },
                                        { lang: 'es', src: ForgetSectionLeftEs },
                                        { lang: 'uk', src: ForgetSectionLeftUk },
                                        { lang: 'us', src: ForgetSectionLeftUs },
                                    ], alt: "App screen", className: "relative w-full max-w-[250px] md:max-w-[450px] lg:left-24" })] }), _jsx(LandingParagraph, { className: "pt-12 text-center lg:hidden", children: _jsx(Trans, { i18nKey: 'landing.forget-section.p4' }) })] }) }) }) }));
}
