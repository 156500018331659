import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Container from '../common/Container';
import LandingSection from './common/LandingSection';
import Image from '../common/Image';
import carouselMaurizio from '../../../assets/images/carousel-1-maurizio.png';
import carouselVittorio from '../../../assets/images/carousel-2-vittorio.png';
import carouselMarta from '../../../assets/images/carousel-3-marta.png';
import carouselGiuliana from '../../../assets/images/carousel-4-giuliana.png';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
const customers = [
    { name: 'maurizio', picture: carouselMaurizio },
    { name: 'giuliana', picture: carouselGiuliana },
    { name: 'marta', picture: carouselMarta },
    { name: 'vittorio', picture: carouselVittorio },
];
export default function CarouselSection() {
    const { t } = useTranslation();
    return (_jsx(LandingSection, { children: _jsx(Container, { children: _jsxs("div", { className: "w-full space-y-20 py-20", children: [_jsx("h2", { className: "text-center text-4xl font-medium lg:text-6xl", children: t('landing.carousel-section.title') }), _jsxs(CarouselProvider, { naturalSlideWidth: 1525, naturalSlideHeight: 433, isIntrinsicHeight: true, totalSlides: 4, infinite: true, isPlaying: true, children: [_jsx(Slider, { children: customers.map(({ name, picture }, index) => (_jsx(Slide, { index: index, children: _jsx(CarouselSlide, { customerName: t(`landing.carousel-section.slide.${name}.name`), job: t(`landing.carousel-section.slide.${name}.job`), comment: t(`landing.carousel-section.slide.${name}.comment`), picture: picture }) }))) }), _jsx(DotGroup, { className: "flex w-full justify-center gap-4", renderDots: ({ currentSlide }) => customers.map((_, index) => (_jsx(CarouselDot, { active: index === currentSlide }))) })] })] }) }) }));
}
function CarouselSlide({ customerName, job, comment, picture, }) {
    return (_jsxs("div", { className: "h-full w-full cursor-default gap-10 lg:flex", children: [_jsx("div", { className: "flex shrink-0 items-center", children: _jsx(Image, { alt: "customer", src: picture, className: "h-fit w-full shrink-0 lg:max-w-[450px]" }) }), _jsxs("div", { className: "mt-4 flex flex-col justify-center gap-4 lg:mt-0", children: [_jsx("h3", { className: "text-4xl xl:text-5xl", children: customerName }), _jsx("p", { className: "text-xl font-light text-day-mode-text-tertiary xl:text-2xl", children: job }), _jsx("p", { className: "text-2xl font-light text-day-mode-text-secondary xl:text-3xl", children: comment })] })] }));
}
function CarouselDot({ active }) {
    return (_jsx("div", { className: `mt-8 h-4 w-4 rounded-full border-4 border-day-mode-brand-blue ${active ? 'bg-day-mode-brand-blue' : ''}` }));
}
