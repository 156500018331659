import { LANGUAGES } from '../config';
let hasLocalStorageSupport;
const localStorageAvailable = () => {
    if (hasLocalStorageSupport !== null)
        return hasLocalStorageSupport;
    try {
        hasLocalStorageSupport =
            typeof window !== 'undefined' && window.localStorage !== null;
        const testKey = 'i18next.translate.boo';
        window.localStorage.setItem(testKey, 'foo');
        window.localStorage.removeItem(testKey);
    }
    catch (e) {
        hasLocalStorageSupport = false;
    }
    return hasLocalStorageSupport;
};
const customPath = {
    name: 'customPath',
    lookup() {
        let found;
        if (typeof window === 'undefined') {
            return found;
        }
        const pathLanguage = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
        if (!(pathLanguage instanceof Array)) {
            return found;
        }
        const cleanPathLanguage = pathLanguage[0].replace('/', '');
        return LANGUAGES.find((lang) => lang.code === cleanPathLanguage)?.code;
    },
    cacheUserLanguage(lng, options) {
        if (options.lookupLocalStorage && localStorageAvailable()) {
            window.localStorage.setItem(options.lookupLocalStorage, lng);
        }
    },
};
export default customPath;
