import { jsx as _jsx } from "react/jsx-runtime";
const bg = {
    'light-blue': 'bg-day-mode-brand-light-blue',
    'none': '',
};
const defaultProps = {
    bg: 'none',
};
export default function LandingSection(props) {
    return (_jsx("section", { className: `overflow-hidden ${bg[props.bg]}`, children: props.children }));
}
LandingSection.defaultProps = defaultProps;
