import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import useIsScrolled from '../../../hooks/useIsScrolled';
import MobileSliderMenu from './MobileSliderMenu';
import Nav from './HeaderNav';
import NavBar from './NavBar';
import HeaderContainer from './HeaderContainer';
import HamburgerButton from './HamburgerButton';
const navLinks = [
    // { title: 'sharing-expenses', url: '#' },
    { title: 'blog', url: '/blog' },
    { title: 'faq', url: '/support' },
];
export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const isScrolled = useIsScrolled(() => setIsOpen(false));
    const toggleHamburgerButton = isOpen
        ? () => setIsOpen(false)
        : () => setIsOpen(true);
    return (_jsxs(HeaderContainer, { isScrolled: isScrolled, children: [_jsxs(NavBar, { isOpen: isOpen, isScrolled: isScrolled, children: [_jsx("h3", { className: "text-2xl", children: "Together Price" }), _jsx("div", { className: "hidden sm:block", children: _jsx(Nav, { links: navLinks }) }), _jsx(HamburgerButton, { isOpen: isOpen, handleClick: toggleHamburgerButton })] }), _jsx(MobileSliderMenu, { navLinks: navLinks, isOpen: isOpen, handleClose: () => setIsOpen(false) })] }));
}
