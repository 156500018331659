import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Layout from './components/Layout';
/* @ts-ignore */
import Seo from '@elegantstack/solid-ui-components/src/Seo';
import './tailwind.css';
import './i18n';
import HeroSection from './components/Landing/HeroSection';
import NeedsSection from './components/Landing/GroupSection';
import PaymenySection from './components/Landing/PaymentSection';
import ForgetSection from './components/Landing/ForgetSection';
import MonitorSection from './components/Landing/MonitorSection';
import { useTranslation } from 'react-i18next';
import ShareSection from './components/Landing/ShareSection';
import CarouselSection from './components/Landing/CarouselSection';
export default function App() {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Seo, { title: t('landing.hero-section.title') }), _jsxs(Layout, { children: [_jsx(HeroSection, {}), _jsx(NeedsSection, {}), _jsx(ShareSection, {}), _jsx(ForgetSection, {}), _jsx(PaymenySection, {}), _jsx(MonitorSection, {}), _jsx(CarouselSection, {})] })] }));
}
