import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Chevron from '../../../icons/Chevron';
import i18n from '../../../../i18n';
import i18next from 'i18next';
import { LANGUAGES } from '../../../../config';
export default function LanguageSelector() {
    const [selected, setSelected] = useState(LANGUAGES[0]);
    const handleLanguageChange = (language) => {
        if (process.env.NODE_ENV === 'development') {
            i18n.changeLanguage(language);
        }
        else {
            window.location.href = `/${language}/expenses`;
        }
    };
    useEffect(() => {
        const detectedLanguage = i18next.language.split('-')[0];
        setSelected(LANGUAGES.find((lang) => lang.code === detectedLanguage) || LANGUAGES[0]);
    });
    return (_jsx(Listbox, { value: selected, onChange: setSelected, children: _jsxs("div", { className: "relative", children: [_jsx(Listbox.Button, { className: 'w-full sm:-ml-2 sm:flex sm:min-w-[4rem] sm:justify-end sm:py-4', children: ({ open }) => {
                        return (_jsxs("div", { className: 'flex items-center space-x-2', children: [_jsx("span", { children: selected.title }), _jsx(Chevron, { className: `transition ${open ? 'rotate-180' : ''}` })] }));
                    } }), _jsx(Transition, { as: Fragment, enter: "transition ease-in duration-100", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0", children: _jsx(Listbox.Options, { className: "mt-4 w-full overflow-hidden rounded-lg border border-day-mode-background-quinary bg-day-mode-text-quaternary py-2 shadow-md outline-1 sm:absolute sm:right-0 sm:top-full sm:mt-2 sm:w-48 sm:py-4", children: LANGUAGES.map((language) => (_jsx(Listbox.Option, { value: language, children: ({ selected }) => (_jsxs("div", { className: "group flex cursor-pointer items-center justify-between px-6 py-4", onClick: () => {
                                    if (!selected)
                                        handleLanguageChange(language.code);
                                }, children: [_jsx("span", { children: language.fullTitle }), _jsx("div", { className: `flex h-6 w-6 items-center justify-center rounded-full  ${selected
                                            ? 'bg-day-mode-brand-blue group-hover:bg-day-mode-brand-blue'
                                            : 'ring-1 ring-day-mode-background-quinary group-hover:bg-day-mode-brand-light-blue'}`, children: _jsx("div", { className: "h-3 w-3 rounded-full bg-day-mode-text-quaternary" }) })] })) }, language.title))) }) })] }) }));
}
