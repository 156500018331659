export const LANGUAGES = [
    {
        title: 'EN(UK)',
        fullTitle: 'English (UK)',
        code: 'uk',
    },
    {
        title: 'EN(US)',
        fullTitle: 'English (US)',
        code: 'us',
    },
    {
        title: 'IT',
        fullTitle: 'Italiano',
        code: 'it',
    },
    {
        title: 'ES',
        fullTitle: 'Español',
        code: 'es',
    },
    /* {
      title: 'PT',
      fullTitle: 'Portugues',
      code: 'pt',
    }, */
];
