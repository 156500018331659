import { jsx as _jsx } from "react/jsx-runtime";
import Container from '../../common/Container';
export default function NavBar(props) {
    const navbarClasses = {
        scrolled: props.isScrolled
            ? 'bg-day-mode-text-quaternary shadow-md'
            : 'bg-day-mode-brand-light-blue',
        sliderOpen: props.isOpen
            ? 'bg-day-mode-text-quaternary shadow-md sm:bg-day-mode-brand-light-blue sm:shadow-none'
            : '',
    };
    return (_jsx("div", { className: `relative z-10 transition duration-300 ${navbarClasses.scrolled} ${navbarClasses.sliderOpen}`, children: _jsx(Container, { children: _jsx("div", { className: "flex w-full content-center items-center justify-between", children: props.children }) }) }));
}
