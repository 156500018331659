import { jsx as _jsx } from "react/jsx-runtime";
import i18next from 'i18next';
import { useEffect, useState } from 'react';
export default function LocalizedImage(props) {
    const [detectedLanguage, setDetectedLanguage] = useState('');
    useEffect(() => {
        const i18lang = i18next.language.split('-')[0];
        setDetectedLanguage(i18lang);
    });
    const { src } = props.sources.find((localizedImage) => localizedImage.lang === detectedLanguage) || props.sources[0];
    const { sources, ...propsWithouthSources } = props;
    return _jsx("img", { ...propsWithouthSources, src: src });
}
