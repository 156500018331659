import { useEffect, useState } from 'react';
export default function useIsScrolled(cb) {
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const scrollHandler = () => {
            cb();
            setIsScrolled(window.scrollY > 0);
        };
        setIsScrolled(window.scrollY > 0);
        addEventListener('scroll', scrollHandler);
        return () => removeEventListener('scroll', scrollHandler);
    });
    return isScrolled;
}
