import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Container from '../common/Container';
import HeroSectionLeftIt from '../../../assets/images/it/HeroSectionLeft_it.png';
import HeroSectionRightIt from '../../../assets/images/it/HeroSectionRight_it.png';
import HeroSectionLeftUk from '../../../assets/images/uk/HeroSectionLeft_uk.png';
import HeroSectionRightUk from '../../../assets/images/uk/HeroSectionRight_uk.png';
import HeroSectionLeftUs from '../../../assets/images/us/HeroSectionLeft_us.png';
import HeroSectionRightUs from '../../../assets/images/us/HeroSectionRight_us.png';
import HeroSectionLeftEs from '../../../assets/images/es/HeroSectionLeft_es.png';
import HeroSectionRightEs from '../../../assets/images/es/HeroSectionRight_es.png';
import LandingSection from './common/LandingSection';
import LocalizedImage from '../common/LocalizedImage';
import StoresButtonGroup from './common/StoresButtonGroup';
export default function HeroSection() {
    const { t } = useTranslation();
    return (_jsx(LandingSection, { bg: "light-blue", children: _jsx(Container, { children: _jsx("div", { className: "max-h-[1080px] sm:flex sm:h-screen sm:min-h-[720px] sm:content-center", children: _jsxs("div", { className: "h-full space-y-10 py-20 text-day-mode-text-primary sm:grid sm:grid-cols-2 sm:items-center 2xl:gap-8", children: [_jsxs("div", { className: "space-y-10", children: [_jsxs("div", { className: "2xl:mr-36", children: [_jsx("h2", { className: "mb-10 text-5xl md:text-6xl xl:leading-tight", children: t('landing.hero-section.title') }), _jsx("h3", { className: "text-xl font-normal lg:text-2xl", children: t('landing.hero-section.subtitle') })] }), _jsx(StoresButtonGroup, { className: "flex w-full justify-center space-x-4 sm:justify-normal" })] }), _jsxs("div", { className: "relative flex translate-x-2 justify-end", children: [_jsx(LocalizedImage, { sources: [
                                        { lang: 'uk', src: HeroSectionLeftUk },
                                        { lang: 'us', src: HeroSectionLeftUs },
                                        { lang: 'es', src: HeroSectionLeftEs },
                                        { lang: 'it', src: HeroSectionLeftIt },
                                    ], alt: "App Screen", className: "absolute -top-4 left-0 z-10 w-4/6 xl:top-6" }), _jsx(LocalizedImage, { sources: [
                                        { lang: 'uk', src: HeroSectionRightUk },
                                        { lang: 'us', src: HeroSectionRightUs },
                                        { lang: 'es', src: HeroSectionRightEs },
                                        { lang: 'it', src: HeroSectionRightIt },
                                    ], alt: "App Screen", className: "relative top-4 w-4/6 xl:-top-6 xl:left-16" })] })] }) }) }) }));
}
