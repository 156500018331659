import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from '../../common/Container';
import Logo from '../../common/Logo';
import Nav from './FooterNav';
import SocialIcons from './SocialIcons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
function getTermsLink(language) {
    switch (language) {
        case 'uk':
            return 'https://www.iubenda.com/terms-and-conditions/57506673';
        case 'us':
            return 'https://www.iubenda.com/terms-and-conditions/62145648';
        case 'it':
            return 'https://www.iubenda.com/termini-e-condizioni/20984338';
        case 'es':
            return 'https://www.iubenda.com/condiciones-de-uso/45646763';
        case 'pt':
            return 'https://www.iubenda.com/termos-e-condicoes/13446623';
        default:
            return 'https://www.iubenda.com/termini-e-condizioni/20984338';
    }
}
function getNavSectionSchema(lang) {
    return [
        {
            title: 'Help',
            links: [
                [
                    'customer-support',
                    lang == 'it' ? '/contatti' : '/contact',
                ],
                ['blog', '/blog'],
                ['faq', '/support'],
            ],
        },
        {
            title: 'Legal',
            links: [
                ['terms-n-conditions', getTermsLink(lang)],
                ['privacy-policy', '/privacy-policy'],
                ['cookies', '/privacy-policy#cookies'],
                ['google-api-disclosure', '/google-api-disclosure'],
            ],
        },
    ];
}
export default function Footer() {
    const { i18n } = useTranslation();
    const [isClient, setIsClient] = useState(0);
    useEffect(() => {
        setIsClient(1);
    }, []);
    return (_jsx("footer", { className: "py-6 text-day-mode-text-primary md:py-12 lg:py-16", children: _jsx(Container, { children: _jsxs("div", { className: "space-y-8 sm:flex sm:flex-col sm:space-y-14", children: [_jsxs("div", { className: "space-y-8 sm:flex sm:space-x-12 sm:space-y-0 md:space-x-24", children: [_jsxs("div", { className: "space-y-8", children: [_jsx(Logo, { className: "w-60" }), _jsx(SocialIcons, {})] }), _jsx(Nav, { sections: getNavSectionSchema(i18n.language) }, isClient)] }), _jsxs("p", { className: "text-sm text-day-mode-text-secondary", children: ["Copyright \u00A9 ", new Date().getFullYear(), " Together Price Ltd - 86 Jermyn St, St. James's, London SW1Y 6AW, UK"] })] }) }) }));
}
