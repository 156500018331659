import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Link from '../../common/Link';
import LocalizedImage from '../../common/LocalizedImage';
import GoogleDownloadIt from '../../../../assets/images/it/google-play_download_it.png';
import AppleDownloadIt from '../../../../assets/images/it/apple-store_download_it.png';
import GoogleDownloadEs from '../../../../assets/images/es/google-play_download_es.png';
import AppleDownloadEs from '../../../../assets/images/es/apple-store_download_es.png';
import GoogleDownloadUk from '../../../../assets/images/uk/google-play_download_uk.png';
import AppleDownloadUk from '../../../../assets/images/uk/apple-store_download_uk.png';
import GoogleDownloadUs from '../../../../assets/images/us/google-play_download_us.png';
import AppleDownloadUs from '../../../../assets/images/us/apple-store_download_us.png';
export default function StoresButtonGroup(props) {
    return (_jsxs("div", { className: props.className, children: [_jsx(Link, { to: "https://play.google.com/store/apps/details?id=com.togetherprice.expenses&pli=1", children: _jsx(LocalizedImage, { sources: [
                        { lang: 'it', src: GoogleDownloadIt },
                        { lang: 'es', src: GoogleDownloadEs },
                        { lang: 'uk', src: GoogleDownloadUk },
                        { lang: 'us', src: GoogleDownloadUs },
                    ], alt: "Get it on Play Store", className: "h-12 cursor-pointer xl:h-16" }) }), _jsx(Link, { to: "https://apps.apple.com/us/app/expenses-by-together-price/id6444769893", children: _jsx(LocalizedImage, { sources: [
                        { lang: 'it', src: AppleDownloadIt },
                        { lang: 'es', src: AppleDownloadEs },
                        { lang: 'uk', src: AppleDownloadUk },
                        { lang: 'us', src: AppleDownloadUs },
                    ], alt: "Download on the App Store", className: "h-12 cursor-pointer xl:h-16" }) })] }));
}
