import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import MonitorSectionIt from '../../../assets/images/it/MonitorSection_it.png';
import MonitorSectionEs from '../../../assets/images/es/MonitorSection_es.png';
import MonitorSectionUk from '../../../assets/images/uk/MonitorSection_uk.png';
import MonitorSectionUs from '../../../assets/images/us/MonitorSection_us.png';
import Container from '../common/Container';
import LandingSection from './common/LandingSection';
import LandingParagraph from './common/LandingParagraph';
import LocalizedImage from '../common/LocalizedImage';
import StoresButtonGroup from './common/StoresButtonGroup';
export default function MonitorSection() {
    const { t } = useTranslation();
    return (_jsx(LandingSection, { bg: "light-blue", children: _jsx(Container, { children: _jsx("div", { className: "lg:flex lg:min-h-[1080px] lg:justify-center", children: _jsxs("div", { className: "space-y-8 py-20 text-day-mode-text-primary lg:grid lg:grid-cols-2 lg:items-center lg:gap-x-32 lg:space-y-0", children: [_jsxs("div", { className: "mx-auto max-w-2xl space-y-6", children: [_jsx("h2", { className: "text-center text-4xl font-medium lg:text-left lg:text-6xl", children: t('landing.monitor-section.title') }), _jsx(LandingParagraph, { className: "text-center lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.monitor-section.p1' }) }), _jsx(LandingParagraph, { className: "text-center lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.monitor-section.p2' }) }), _jsx(LandingParagraph, { className: "hidden text-center lg:block lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.monitor-section.p3' }) }), _jsx(LandingParagraph, { className: "hidden text-center lg:block lg:text-left", children: _jsx(Trans, { i18nKey: 'landing.monitor-section.p4' }) })] }), _jsx("div", { className: "flex justify-center", children: _jsx(LocalizedImage, { sources: [
                                    { lang: 'it', src: MonitorSectionIt },
                                    { lang: 'es', src: MonitorSectionEs },
                                    { lang: 'uk', src: MonitorSectionUk },
                                    { lang: 'us', src: MonitorSectionUs },
                                ], alt: "App screen", className: "w-full max-w-[250px] md:max-w-[295px]" }) }), _jsxs("div", { className: "mx-auto max-w-md space-y-6 pt-8 lg:hidden", children: [_jsx(LandingParagraph, { className: "mx-auto max-w-md text-center ", children: _jsx(Trans, { i18nKey: 'landing.monitor-section.p3' }) }), _jsx(LandingParagraph, { className: "mx-auto max-w-md text-center ", children: _jsx(Trans, { i18nKey: 'landing.monitor-section.p4' }) })] }), _jsx(StoresButtonGroup, { className: "col-span-2 flex w-full justify-center space-x-4" })] }) }) }) }));
}
