import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Link from '../../../common/Link';
export default function Section(props) {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx("h2", { className: "mb-2 text-lg", children: props.section.title }), _jsx("ul", { className: "mt-4 space-y-2", children: props.section.links.map((link) => {
                    const [name, url] = link;
                    return (_jsx("li", { children: _jsx(Link, { className: "text-sm text-day-mode-text-secondary", to: url, children: t(`layout.nav.${name}`) }) }, name));
                }) })] }));
}
